import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Healthcare.css';
import HealthcareImage from '../images/healthcareCard.jpeg';
import Highlight from './Highlight';

interface HealthcareFacility {
  facility_name: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  phone_number: string;
  website_url: string;
}

interface SearchSuggestion {
  text: string;
  type: 'facility' | 'location';
}

const HealthcarePage: React.FC = () => {
  const [healthcareData, setHealthcareData] = useState<HealthcareFacility[]>([]);
  const [images, setImages] = useState<{ [key: string]: string }>({}); // Added state to store dynamically fetched images
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [pageInput, setPageInput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [filterCity, setFilterCity] = useState<string>('');
  const [filterState, setFilterState] = useState<string>('');
  const [filterZip, setFilterZip] = useState<string>('');
  const [filterArea, setFilterArea] = useState<string>('');
  const [filterHasWebsite, setFilterHasWebsite] = useState<string>('');

  useEffect(() => {
    fetchHealthcareData();
  }, [searchQuery]);

  useEffect(() => {
    document.title = "Healthcare Programs";
    // Add click event listener to close suggestions when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const defaultImageUrl = HealthcareImage; // Fallback to a default image if no relevant image is found

  useEffect(() => {
    document.title = "Healthcare Programs";
    const fetchSuggestions = async () => {
      if (searchInput.length >= 2) {
        try {
          const response = await fetch(`https://immigraid-backend.immigraid.me/api/search-suggestions?prefix=${encodeURIComponent(searchInput)}`);
          if (response.ok) {
            const data = await response.json();
            setSuggestions(data);
            setShowSuggestions(true);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchSuggestions();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchInput]);


  // const fetchHealthcareData = () => {
  //   setLoading(true);
  //   const apiUrl = searchQuery
  //     ? `https://immigraid-backend.immigraid.me/api/search?query=${searchQuery}&sort_by=facility_name&order=asc&limit=10`
  //     : `https://immigraid-backend.immigraid.me/api/healthcare-data`;

  //   fetch(apiUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Handle both search results and initial data load
  //       let healthcareArray;
  //       if (searchQuery) {
  //         // If it's a search result, data structure might be different
  //         healthcareArray = Array.isArray(data) ? data : data.healthcare || [];
  //       } else {
  //         // If it's the initial load, expect data in the healthcare property
  //         healthcareArray = Array.isArray(data) ? data : (data.healthcare || []);
  //       }

  //       setHealthcareData(healthcareArray);
  //       setTotalPages(Math.ceil(healthcareArray.length / itemsPerPage));
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       setError('Error fetching healthcare data');
  //       setHealthcareData([]);
  //       setLoading(false);
  //     });
  // };

  const fetchHealthcareData = () => {
    setLoading(true);
    const apiUrl = searchQuery
      ? `https://immigraid-backend.immigraid.me/api/search?query=${searchQuery}&sort_by=facility_name&order=asc&limit=10`
      : `https://immigraid-backend.immigraid.me/api/healthcare-data?&city=${filterCity}&state=${filterState}&zip_code=${filterZip}&area_code=${filterArea}&has_website=${filterHasWebsite}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle both search results and initial data load
        let healthcareArray;
        if (searchQuery) {
          // If it's a search result, data structure might be different
          healthcareArray = Array.isArray(data) ? data : data.healthcare || [];
        } else {
          // If it's the initial load, expect data in the healthcare property
          healthcareArray = Array.isArray(data) ? data : (data.healthcare || []);
        }
        setHealthcareData(healthcareArray);
        setTotalPages(Math.ceil(healthcareArray.length / itemsPerPage));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Error fetching healthcare data');
        setHealthcareData([]);
        setLoading(false);
      });
  };
  
  
  const handleSearchClick = () => {
    setSearchQuery(searchInput);
    setCurrentPage(1);
  };

  // Reset search
  const handleClearSearch = () => {
    setSearchInput('');
    setSearchQuery('');
    setCurrentPage(1);
  };


  // Fetch images for healthcare facilities dynamically from Unsplash
  useEffect(() => {
    const fetchImages = async () => {
      const fetchImageForFacility = async (facility: HealthcareFacility) => {
        try {
          const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${facility.facility_name}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          );
          const data = await response.json();
          const imageUrl = data.results[0]?.urls?.regular || defaultImageUrl; // Use fetched image or fallback
          setImages((prev) => ({ ...prev, [facility.facility_name]: imageUrl })); // Store image in state
        } catch (error) {
          console.error(`Error fetching image for ${facility.facility_name}:`, error);
          setImages((prev) => ({ ...prev, [facility.facility_name]: defaultImageUrl })); // Store fallback image on error
        }
      };

      healthcareData.forEach(fetchImageForFacility); // Fetch image for each facility
    };

    if (healthcareData.length > 0) {
      fetchImages();
    }
  }, [healthcareData]); // Runs whenever `healthcareData` changes

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = healthcareData.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setTotalPages(Math.ceil(healthcareData.length / newItemsPerPage));
    setCurrentPage(1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const goToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageInput('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSuggestionClick = (suggestion: SearchSuggestion) => {
    setSearchInput(suggestion.text);
    setSearchQuery(suggestion.text);
    setShowSuggestions(false);
    setCurrentPage(1);
  };

  const handleCityFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCity(e.target.value);
    setCurrentPage(1); // Reset to page 1 whenever filter changes
  };
  
  const handleStateFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState(e.target.value);
    setCurrentPage(1); // Reset to page 1 whenever filter changes
  };
  
  const handleZipFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterZip(e.target.value);
    setCurrentPage(1); // Reset to page 1 whenever filter changes
  };
  if (loading) {
    return <p>Loading healthcare data...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container">
      <h1>Healthcare Resources</h1>

      {/* Search Bar with Clear Button */}
      <div ref={searchContainerRef} style={{ marginBottom: '20px', position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            placeholder="Search healthcare facilities..."
            value={searchInput}
            onChange={handleSearchInputChange}
            style={{ padding: '8px', width: '100%' }}
          />
          <button onClick={() => handleSearchClick()} style={{ marginLeft: '10px', padding: '8px 16px' }}>
            Search
          </button>
          {searchQuery && (
            <button onClick={handleClearSearch} style={{ marginLeft: '10px', padding: '8px 16px' }}>
              Clear
            </button>
          )}
        </div>

      {/* Filtering */}
      {/* <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="City"
          value={filterCity}
          onChange={(e) => setFilterCity(e.target.value)}
          style={{ padding: '8px', marginRight: '10px', width: '22%' }}
        />
        <input
          type="text"
          placeholder="State"
          value={filterState}
          onChange={(e) => setFilterState(e.target.value)}
          style={{ padding: '8px', marginRight: '10px', width: '22%' }}
        />
        <input
          type="text"
          placeholder="Zip Code"
          value={filterZip}
          onChange={(e) => setFilterZip(e.target.value)}
          style={{ padding: '8px', width: '22%' }}
        />
      </div> */}
        {/* Suggestions Dropdown */}
        {showSuggestions && suggestions.length > 0 && (
          <div style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            background: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            zIndex: 1000,
          }}>
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{
                  padding: '8px 16px',
                  cursor: 'pointer',
                  borderBottom: index < suggestions.length - 1 ? '1px solid #eee' : 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = 'white';
                }}
              >
                <span style={{ 
                  marginRight: '8px',
                  color: suggestion.type === 'facility' ? '#2563eb' : '#059669'
                }}>
                  {suggestion.type === 'facility' ? '🏥' : '📍'}
                </span>
                {suggestion.text}
              </div>
            ))}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="itemsPerPage">Items per page: </label>
        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>
      <div style={{ marginBottom: '20px' }}>
      <label htmlFor="stateFilter">State: </label>
      <select id="stateFilter" value={filterState} onChange={(e) => setFilterState(e.target.value)}>
      <option value="">All</option>
      <option value="AL">AL</option>
      <option value="AK">AK</option>
      <option value="AZ">AZ</option>
      <option value="AR">AR</option>
      <option value="CA">CA</option>
      <option value="CO">CO</option>
      <option value="CT">CT</option>
      <option value="DE">DE</option>
      <option value="FL">FL</option>
      <option value="GA">GA</option>
      <option value="HI">HI</option>
      <option value="ID">ID</option>
      <option value="IL">IL</option>
      <option value="IN">IN</option>
      <option value="IA">IA</option>
      <option value="KS">KS</option>
      <option value="KY">KY</option>
      <option value="LA">LA</option>
      <option value="ME">ME</option>
      <option value="MD">MD</option>
      <option value="MA">MA</option>
      <option value="MI">MI</option>
      <option value="MN">MN</option>
      <option value="MS">MS</option>
      <option value="MO">MO</option>
      <option value="MT">MT</option>
      <option value="NE">NE</option>
      <option value="NV">NV</option>
      <option value="NH">NH</option>
      <option value="NJ">NJ</option>
      <option value="NM">NM</option>
      <option value="NY">NY</option>
      <option value="NC">NC</option>
      <option value="ND">ND</option>
      <option value="OH">OH</option>
      <option value="OK">OK</option>
      <option value="OR">OR</option>
      <option value="PA">PA</option>
      <option value="RI">RI</option>
      <option value="SC">SC</option>
      <option value="SD">SD</option>
      <option value="TN">TN</option>
      <option value="TX">TX</option>
      <option value="UT">UT</option>
      <option value="VT">VT</option>
      <option value="VA">VA</option>
      <option value="WA">WA</option>
      <option value="WV">WV</option>
      <option value="WI">WI</option>
      <option value="WY">WY</option>
      </select>

      <label htmlFor="cityFilter">City: </label>
      <input 
        id="cityFilter" 
        value={filterCity} 
        onChange={(e) => setFilterCity(e.target.value)} 
        placeholder="Enter city" 
      />

      <label htmlFor="zipCodeFilter">ZIP Code: </label>
      <input 
        id="zipCodeFilter" 
        value={filterZip} 
        onChange={(e) => setFilterZip(e.target.value)} 
        placeholder="Enter ZIP code" 
      />

      <label htmlFor="areaCodeFilter">Area Code: </label>
      <input 
        id="areaCodeFilter" 
        value={filterArea} 
        onChange={(e) => setFilterArea(e.target.value)} 
        placeholder="Enter area code" 
      />
      <label htmlFor="areaCodeFilter">Area Code: </label>


      <label htmlFor="hasWebsite">Has Website: </label>
      <select
        id="hasWebsite"
        value={filterHasWebsite}
        onChange={(e) => setFilterHasWebsite(e.target.value)}
      >
        <option value="">All</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    </div>
<button onClick={fetchHealthcareData}>Apply Filters</button>
<button onClick={() => {
    setFilterState("");
    setFilterCity("");
    setFilterZip("");
    setFilterArea("");
    setFilterHasWebsite("");
}}>Clear Filters</button>

      {healthcareData.length === 0 ? (
        <p>No healthcare facilities found.</p>
      ) : (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '8px',
          maxWidth: '100%',
          padding: '16px',
          boxSizing: 'border-box',
          margin: '0 auto',
        }}>
          {currentItems.map((facility, index) => (
            <Link
              key={index}
              to={`/healthcare/${encodeURIComponent(facility.facility_name)}`}
              style={{
                border: '1px solid #ddd',
                padding: '16px',
                borderRadius: '8px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                flex: '0 1 30%',
                margin: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '250px',
                height: '450px',
                overflow: 'hidden',
                textDecoration: 'none',  // Remove underline from the whole card
                color: 'inherit'         // Inherit text color instead of using link color
              }}
            >
              <div style={{ width: '100%', maxHeight: '200px', overflow: 'hidden', marginBottom: '10px' }}>
              <img 
                src={images[facility.facility_name] || HealthcareImage} 
                alt={facility.facility_name || 'Healthcare Facility'} 
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              </div>
              <h2 style={{ margin: '0', color: 'black' }}>
                <Highlight
                  text={facility.facility_name || 'Unknown Facility'}
                  searchTerm={searchQuery}
                />  
              </h2>
              <p style={{
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'normal',
                color: 'black'  // Ensure paragraph text is black
              }}>
                <Highlight
                  text={`${facility.street_address || 'Unknown Address'}, ${facility.city || 'Unknown City'},
                  ${facility.state || 'Unknown State'} ${facility.zip_code || 'Unknown ZIP'}`}
                  searchTerm={searchQuery}
                />
              </p>
              <p style={{ color: 'black' }}>Phone: {facility.phone_number || 'N/A'}</p>
              <a 
                href={facility.website_url || '#'} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{
                  color: '#2563eb',          // Blue color for the link
                  textDecoration: 'underline' // Only underline the website link
                }}
              >
                {facility.website_url ? 'Visit Website' : 'No Website'}
              </a>
            </Link>
          ))}
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          type="number"
          value={pageInput}
          onChange={handlePageInputChange}
          placeholder={String(currentPage)}
          min="1"
          max={totalPages}
          style={{ padding: '8px' }}
        />
        <button onClick={goToPage} style={{ padding: '8px 16px', marginLeft: '10px' }}>
          Go to Page
        </button>
      </div>
    </div>
  );
};

export default HealthcarePage;
