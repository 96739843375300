import React from 'react';
import { Link } from 'react-router-dom';
import mortarboardIcon from '../icons/mortarboard.svg'; // Path to Education icon
import heartPulseIcon from '../icons/heart-pulse.svg'; // Path to Healthcare icon
import searchIcon from '../icons/search.svg'; // Path to Legal icon

const Navbar: React.FC = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <Link className="navbar-brand ms-2" to="/">ImmigriAid</Link>
    <button 
        className="navbar-toggler" 
        type="button" 
        data-toggle="collapse" 
        data-target="#navbarNav" 
        aria-controls="navbarNav" 
        aria-expanded="false" 
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul className="navbar-nav mx-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/EducationalPrograms">
              <img src={mortarboardIcon} alt="Education Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
              Education
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/healthcare">
              <img src={heartPulseIcon} alt="Healthcare Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
              Healthcare
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/legalservices">
              <img src={searchIcon} alt="Legal Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
              Legal
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
