import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import EducationImage from '../images/educationCard.jpeg'; // Default image

interface Program {
  id: number;
  name: string;
  location: string;
  state?: string; // Made optional
  city?: string;  // Made optional
  description: string;
  imageUrl?: string;
  additional_content?: string;
}

const EducationalPrograms: React.FC = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const [filteredPrograms, setFilteredPrograms] = useState<Program[]>([]); // To store filtered programs
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null); // Error message
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5); // Make this customizable
  const [pageInput, setPageInput] = useState<string>(''); // State for user input page number
  const [images, setImages] = useState<{ [key: number]: string }>({}); // To store fetched images
  const [searchInput, setSearchInput] = useState<string>(''); // Search input
  const [filterState, setFilterState] = useState<string>(''); // State filter
  const [filterCity, setFilterCity] = useState<string>(''); // City filter
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false); // Tracks if search is active

  const defaultImageUrl = EducationImage; // Default fallback image

  useEffect(() => {
    document.title = 'Educational Programs';

    // Fetch data from the Flask API
    fetch('https://immigraid-backend.immigraid.me/api/educational-data')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setPrograms(data);
          setFilteredPrograms(data); // Set initial filtered programs
          setLoading(false);
        } else {
          console.error('Unexpected data format:', data);
          setError('Unexpected data format received.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error loading programs:', error);
        setError('Error loading programs');
        setLoading(false);
      });
  }, []);

  // Fetch images for programs from Unsplash
  useEffect(() => {
    const fetchImages = async () => {
      const fetchImageForProgram = async (program: Program) => {
        try {
          const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${program.name}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          );
          const data = await response.json();
          const imageUrl = data.results[0]?.urls?.regular || defaultImageUrl;
          setImages((prev) => ({ ...prev, [program.id]: imageUrl }));
        } catch (error) {
          console.error(`Error fetching image for ${program.name}:`, error);
          setImages((prev) => ({ ...prev, [program.id]: defaultImageUrl }));
        }
      };

      programs.forEach(fetchImageForProgram);
    };

    if (programs.length > 0) {
      fetchImages();
    }
  }, [programs]);

  // Apply filters and search only when the search button is clicked
  const handleSearch = () => {
    let updatedPrograms = programs;

    // Apply search filter
    if (searchInput) {
      updatedPrograms = updatedPrograms.filter(
        (program) =>
          program.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          program.description.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Apply state filter
    if (filterState) {
      updatedPrograms = updatedPrograms.filter(
        (program) => program.state?.toLowerCase().includes(filterState.toLowerCase())
      );
    }

    // Apply city filter
    if (filterCity) {
      updatedPrograms = updatedPrograms.filter(
        (program) => program.city?.toLowerCase().includes(filterCity.toLowerCase())
      );
    }

    setFilteredPrograms(updatedPrograms);
    setIsSearchActive(true);

    // If no results are found, show an error message
    if (updatedPrograms.length === 0) {
      setError('No programs match your search criteria.');
    } else {
      setError(null); // Clear error if results are found
    }

    setCurrentPage(1); // Reset to the first page after filtering
  };

  const clearFilters = () => {
    setSearchInput('');
    setFilterState('');
    setFilterCity('');
    setFilteredPrograms(programs); // Reset to all programs
    setError(null); // Clear any existing error
    setIsSearchActive(false);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredPrograms.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPrograms.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPageInput(value);
  };

  const goToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageInput('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  if (loading) {
    return <p>Loading educational programs...</p>;
  }

  if (error && isSearchActive) {
    return <p>{error}</p>;
  }

  if (filteredPrograms.length === 0 && isSearchActive) {
    return <p>No programs available at the moment.</p>;
  }

  return (
    <div className="container">
      <h1 className="mt-5">Educational Programs</h1>

      {/* Search and Filter Section */}
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Search programs..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{ padding: '8px', flex: 1 }}
        />
        <input
          type="text"
          placeholder="Filter by state..."
          value={filterState}
          onChange={(e) => setFilterState(e.target.value)}
          style={{ padding: '8px', width: '150px' }}
        />
        <input
          type="text"
          placeholder="Filter by city..."
          value={filterCity}
          onChange={(e) => setFilterCity(e.target.value)}
          style={{ padding: '8px', width: '150px' }}
        />
        <button onClick={handleSearch} style={{ padding: '8px 16px' }}>
          Search
        </button>
        <button onClick={clearFilters} style={{ padding: '8px 16px' }}>
          Clear Filters
        </button>
      </div>

      <div style={dropdownStyle}>
        <label htmlFor="itemsPerPage">Items per page: </label>
        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>
      <div style={cardContainerStyle}>
        <div className="row" id="instances-container">
          {currentItems.map((program) => (
            <div className="col-md-4 instance-card mb-4" key={program.id}>
              <Link to={`/programs/${program.id}`} className="text-decoration-none">
                <div className="card" style={educationCardStyle}>
                  <img
                    src={images[program.id] || defaultImageUrl}
                    className="card-img-top"
                    alt={program.name}
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{program.name}</h5>
                    <p className="card-text">Location: {program.location}</p>
                    <p style={descriptionStyle} className="card-text">
                      Description: {program.description}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination-controls" style={paginationStyle}>
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      <div style={jumpToPageStyle}>
        <input
          type="number"
          value={pageInput}
          onChange={handlePageInputChange}
          placeholder={String(currentPage)}
          min="1"
          max={totalPages}
          style={pageInputStyle}
        />
        <button onClick={goToPage} style={goButtonStyle}>
          Go
        </button>
      </div>
    </div>
  );
};

const educationCardStyle: React.CSSProperties = {
  border: '1px solid #ddd',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  margin: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '450px',
};

const descriptionStyle: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
};

const cardContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  marginTop: '20px',
};

const paginationStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
};

const dropdownStyle: React.CSSProperties = {
  marginBottom: '20px',
};

const jumpToPageStyle: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const pageInputStyle: React.CSSProperties = {
  marginRight: '10px',
  padding: '5px',
  width: '60px',
};

const goButtonStyle: React.CSSProperties = {
  padding: '5px 10px',
};

export default EducationalPrograms;
