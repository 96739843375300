import React, { ReactElement } from 'react';

interface HighlightProps {
  text?: string;
  searchTerm?: string;
}

const Highlight: React.FC<HighlightProps> = ({ text = '', searchTerm = '' }): ReactElement => {
  // Early return if either text or searchTerm is missing
  if (!text) {
    return <>{text}</>;
  }
  
  if (!searchTerm.trim()) {
    return <>{text}</>;
  }
  
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
  const parts = text.split(regex);
  
  return (
    <>
      {parts.map((part, index) => {
        const isMatch = part.toLowerCase() === searchTerm.toLowerCase();
        return isMatch ? (
          <span 
            key={index} 
            className="bg-yellow-200 font-medium"
            style={{ backgroundColor: '#fef08a' }} // Fallback color in case Tailwind doesn't load
          >
            {part}
          </span>
        ) : (
          <React.Fragment key={index}>{part}</React.Fragment>
        );
      })}
    </>
  );
};

export default Highlight;