import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles.css';
import kohler from '../images/bkc.webp';
import catholic from '../images/ccdallas.png';
import ag from '../images/ag.png';

// Define the type for each instance of legal assistance
interface LegalAssistanceInstance {
    id: number;
    name: string;
    image: string;
    location: string;
    phone: string;
    website: string;
    appointmentsAccepted: string;
    hoursOpen: string;
    link: string;
}

const LegalAssistance: React.FC = () => {
    const [totalInstances, setTotalInstances] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    const instancesPerPage: number = 9;

    // Sample data for legal assistance instances
    const instances: LegalAssistanceInstance[] = [
        {
            id: 1,
            name: "Bernardo Kohler Center",
            image: kohler,
            location: "4009 Bannister Ln, Suite 240, Austin, TX 78704",
            phone: "(512) 831-4272",
            website: "http://www.bernardokohler.org",
            appointmentsAccepted: "Yes",
            hoursOpen: "8:30 AM - 4:30 PM",
            link: "bkc.html"
        },
        {
            id: 2,
            name: "Catholic Charities Dallas",
            image: catholic,
            location: "1421 W. Mockingbird Lane, Dallas, TX 75247",
            phone: "(214) 634-7182",
            website: "https://ccdallas.org/need-help/immigration-legal-services/",
            appointmentsAccepted: "Yes",
            hoursOpen: "7:30 AM - 5:30 PM",
            link: "ccdallas.html"
        },
        {
            id: 3,
            name: "American Gateways (Austin Office)",
            image: ag,
            location: "314 E. Highland Mall Blvd., Suite 501, Austin, TX 78752",
            phone: "(512) 478-0546 ext. 200",
            website: "http://www.americangateways.org",
            appointmentsAccepted: "No",
            hoursOpen: "10 AM - 2 PM",
            link: "agateways.html"
        }
        // Add more legal assistance instances here
    ];

    useEffect(() => {
        const total = instances.length;
        setTotalInstances(total);
        setTotalPages(Math.ceil(total / instancesPerPage));
    }, [instances]);

    return (
        <div>
            <div className="container">
                <h1 className="mt-5">Legal Assistance</h1>

                {/* Grid of Instances */}
                <div className="row" id="instances-container">
                    {instances.map((instance) => (
                        <div key={instance.id} className="col-md-4 instance-card mb-4">
                            <a href={instance.link} className="text-decoration-none">
                                <div className="card h-100">
                                    <img src={instance.image} className="card-img-top img-fluid" alt={instance.name} />
                                    <div className="card-body">
                                        <h5 className="card-title">{instance.name}</h5>
                                        <p className="card-text"><strong>Location:</strong> {instance.location}</p>
                                        <p className="card-text"><strong>Phone:</strong> {instance.phone}</p>
                                        <p className="card-text"><strong>Website:</strong> <a href={instance.website} target="_blank" rel="noopener noreferrer">{instance.website}</a></p>
                                        <p className="card-text"><strong>Appointments Accepted:</strong> {instance.appointmentsAccepted}</p>
                                        <p className="card-text"><strong>Hours Open:</strong> {instance.hoursOpen}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                {/* Footer with Total Instances and Pages */}
                <footer className="text-center mt-5">
                    <p id="footer-info" className="footer-info">Total Instances: {totalInstances} | Total Pages: {totalPages}</p>
                </footer>
            </div>
        </div>
    );
};

export default LegalAssistance;
