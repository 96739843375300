import React, { useEffect, useState } from "react";
import "../styles.css";
import michaelImage from '../images/_JAS8739.jpg';
import shrutiImage from '../images/shruti.jpeg';
import sahanaImage from '../images/sahana.jpeg';
import franciscoImage from '../images/photo4.jpeg';
import hurleyImage from '../images/hurley.jpeg';

// Define types for state
interface ContributorCounts {
  issues: number;
  commits: number;
}

interface CountsState {
  michael: ContributorCounts;
  shruti: ContributorCounts;
  sahana: ContributorCounts;
  francisco: ContributorCounts;
  hurley: ContributorCounts;
  total: {
    issues: number;
    commits: number;
    unitTests: number;
  };
}

const projectId = '61878523'; 
const accessToken = "glpat-n7oX9pVf6kTTfp3PFP73"; 
// const accessToken = "glpat-Ep-ee99o2iHAmLraTRba"; 

const About: React.FC = () => {
  const [counts, setCounts] = useState<CountsState>({
    michael: { issues: 0, commits: 0 },
    shruti: { issues: 0, commits: 0 },
    sahana: { issues: 0, commits: 0 },
    francisco: { issues: 0, commits: 0 },
    hurley: { issues: 0, commits: 0 },
    total: { issues: 0, commits: 0, unitTests: 0 }
  });

  async function getIssuesPerContributor(): Promise<Record<string, ContributorCounts> | undefined> {
    try {
      const response = await fetch(`https://gitlab.com/api/v4/projects/${projectId}/issues?state=closed&per_page=100&page=1`, {
        headers: {
          'PRIVATE-TOKEN': accessToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const issues = await response.json();
      const issuesClosedCount: Record<string, ContributorCounts> = {};

      // Count issues per contributor using the username
      issues.forEach((issue : { closed_by: { username: string } }) => {
        if (issue.closed_by) {
          const closedByUsername = issue.closed_by.username;
          if (!issuesClosedCount[closedByUsername]) {
            issuesClosedCount[closedByUsername] = { issues: 0, commits: 0 };
          }
          issuesClosedCount[closedByUsername].issues++;
        }
      });

      return issuesClosedCount;
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  }

  async function getCommitsPerContributor(): Promise<Record<string, ContributorCounts> | undefined> {
    try {
      const response = await fetch(`https://gitlab.com/api/v4/projects/${projectId}/repository/commits?per_page=100&page=1`, {
        headers: {
          'PRIVATE-TOKEN': accessToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const commits = await response.json();
      const commitsCount: Record<string, ContributorCounts> = {};

      // Count commits per contributor using the username
      commits.forEach((commit: { author_name: string }) => {
        const authorUsername = commit.author_name;
        if (!commitsCount[authorUsername]) {
          commitsCount[authorUsername] = { issues: 0, commits: 0 };
        }
        commitsCount[authorUsername].commits++;
      });

      return commitsCount;
    } catch (error) {
      console.error('Error fetching commits:', error);
    }
  }

  async function updateCounts() {
    const issuesCount = await getIssuesPerContributor();
    const commitsCount = await getCommitsPerContributor();

    const updatedCounts: CountsState = {
      michael: {
        issues: issuesCount?.["michaeltao07"]?.issues || 0,
        commits: commitsCount?.["Michael Tao"]?.commits || 0
      },
      shruti: {
        issues: issuesCount?.["sbagale"]?.issues || 0,
        commits: commitsCount?.["Shruti Bagale"]?.commits || 0
      },
      sahana: {
        issues: issuesCount?.["sahanagana1"]?.issues || 0,
        commits: commitsCount?.["Sahana Ganapathy"]?.commits || 0
      },
      francisco: {
        issues: issuesCount?.["flameychicken"]?.issues || 0,
        commits: commitsCount?.["Francisco Torres"]?.commits || 0
      },
      hurley: {
        issues: issuesCount?.["HurleyQi"]?.issues || 0,
        commits: commitsCount?.['Kangsheng ("Hurley") Qi']?.commits || 0
      },
      total: {
        issues: 0,
        commits: 0,
        unitTests: 0 // Placeholder for unit tests if needed
      }
    };

    // Calculate totals
    Object.values(updatedCounts).forEach(member => {
      updatedCounts.total.issues += member.issues;
      updatedCounts.total.commits += member.commits;
    });

    setCounts(updatedCounts);
  }

  useEffect(() => {
    updateCounts();
    document.title = "About";
  }, []);

  return (
    <div className="container">
      <h1 className="mt-5">About This Site</h1>
      <p>This site showcases various models and their attributes, aimed at users interested in data visualization and analysis.</p>

      <h2>Group Members</h2>
      <div className="row">
        {/* Michael Tao */}
        <div className="col-md-4">
          <div className="card mb-4">
            <img src={michaelImage} className="card-img-top" alt="Michael Tao" />
            <div className="card-body">
              <h5 className="card-title">Michael Tao</h5>
              <p>Hi! I'm a junior CS major here at UT Austin with a minor in Statistics and Data Science. I'm interested in software engineering and machine learning!</p>
              <p>Responsibilities: Assisting with RFP, Updating About Page, Developing Instance Pages, Obtaining domain and hosting on AWS</p>
              <p>Commits: {counts.michael.commits} | Issues: {counts.michael.issues}</p>
            </div>
          </div>
        </div>

        {/* Shruti Bagale */}
        <div className="col-md-4">
          <div className="card mb-4">
            <img src={shrutiImage} className="card-img-top" alt="Shruti Bagale" />
            <div className="card-body">
              <h5 className="card-title">Shruti Bagale</h5>
              <p>I'm a junior at UT Austin and I'm currently studying Computer Science with a minor in Economics.</p>
              <p>Responsibilities: Front-end, Creating Model Instances, Research</p>
              <p>Commits: {counts.shruti.commits} | Issues: {counts.shruti.issues}</p>
            </div>
          </div>
        </div>

        {/* Sahana Ganapathy */}
        <div className="col-md-4">
          <div className="card mb-4">
            <img src={sahanaImage} className="card-img-top" alt="Sahana Ganapathy" />
            <div className="card-body">
              <h5 className="card-title">Sahana Ganapathy</h5>
              <p>I'm a junior at UT majoring in Computer Science and looking to specialize in Computer Security/Networks.</p>
              <p>Responsibilities: Front-end + Back-end</p>
              <p>Commits: {counts.sahana.commits} | Issues: {counts.sahana.issues}</p>
            </div>
          </div>
        </div>

        {/* Francisco Torres */}
        <div className="col-md-4">
          <div className="card mb-4">
            <img src={franciscoImage} className="card-img-top" alt="Francisco Torres" />
            <div className="card-body">
              <h5 className="card-title">Francisco Torres</h5>
              <p>I'm a Computer Science student at the University of Texas. Currently in my junior year and I enjoy learning about back-end and front-end programming.</p>
              <p>Responsibilities: Postman API | Front-End | Back-End | RFP | Instance Pages</p>
              <p>Commits: {counts.francisco.commits} | Issues: {counts.francisco.issues}</p>
            </div>
          </div>
        </div>

        {/* Kangsheng ("Hurley") Qi */}
        <div className="col-md-4">
          <div className="card mb-4">
            <img src={hurleyImage} className="card-img-top" alt="Kangsheng ('Hurley') Qi" />
            <div className="card-body">
              <h5 className="card-title">Kangsheng ("Hurley") Qi</h5>
              <p>Hello, my name is Kangsheng ("Hurley") Qi. I am a Computer Science and Mathematics major at the University of Texas at Austin.</p>
              <p>Responsibilities: Assisting with API + RFP | Front-End | Back-End | Research</p>
              <p>Commits: {counts.hurley.commits} | Issues: {counts.hurley.issues}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
