import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles.css';
import raoMedical from '../images/RaoMedical.jpeg';
import roberto from '../images/pena-medical.webp';
import loann from '../images/dr-loann.png';

const HealthcarePrograms: React.FC = () => {
    const [totalInstances, setTotalInstances] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    const instancesPerPage: number = 9;

    useEffect(() => {
        // Select all elements with the class 'instance-card'
        const instances = document.querySelectorAll<HTMLDivElement>('.instance-card');
        const total = instances.length;
        setTotalInstances(total);
        setTotalPages(Math.ceil(total / instancesPerPage));
    }, []);

    return (
        <div>
            <div className="container">
                <h1 className="mt-5">Healthcare Programs</h1>

                {/* Filter and Sort Options */}
                <div className="filter-sort mb-4">
                    <label htmlFor="filter">Filter by Gender: </label>
                    <select id="filter" className="mr-3">
                        <option value="all">All</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <label htmlFor="sort">Sort by Location: </label>
                    <select id="sort">
                        <option value="asc">Austin</option>
                        <option value="desc">Round Rock</option>
                    </select>
                </div>

                <div className="row" id="instances-container">
                    {/* Instance for Rao Medical PLLC */}
                    <div className="col-md-4 instance-card mb-4">
                        <a href="rao-medical.html" className="card mb-4">
                            <img src={raoMedical} className="card-img-top" alt="Rao Medical PLLC" style={{ objectFit: 'cover' }} />
                        </a>
                        <div className="card-body">
                            <h5 className="card-title">Rao Medical PLLC</h5>
                            <p className="card-text"><a href="https://www.firstpriorityintegrativecare.com/">Website</a></p>
                            <p className="card-text"><strong>Location:</strong> 1300 W Koenig Lane Suite 103, Austin, TX 78756</p>
                            <p className="card-text"><strong>Phone:</strong> 512-850-5565</p>
                            <p className="card-text"><strong>Additional Languages Spoken:</strong> HINDI, Punjabi</p>
                            <p className="card-text"><strong>Gender:</strong> Male</p>
                            <p className="card-text"><strong>Name:</strong> Dr. Sahil Rao</p>
                        </div>
                    </div>

                    {/* Instance for Dr. Roberto M Pena */}
                    <div className="col-md-4 instance-card mb-4">
                        <a href="pena-medical.html" className="card mb-4">
                            <img src={roberto} className="card-img-top" alt="Dr. Roberto M Pena" style={{ objectFit: 'cover' }} />
                        </a>
                        <div className="card-body">
                            <h5 className="card-title">Dr. Roberto M Pena</h5>
                            <p className="card-text"><a href="https://www.robertopenacivilsurgeon.com/">Website</a></p>
                            <p className="card-text"><strong>Location:</strong> 2911 Medical Arts Street Building 14, Austin, TX 78705</p>
                            <p className="card-text"><strong>Phone:</strong> 512-477-4693</p>
                            <p className="card-text"><strong>Additional Language Spoken:</strong> Spanish</p>
                            <p className="card-text"><strong>Gender:</strong> Male</p>
                            <p className="card-text"><strong>Name:</strong> Dr. Roberto M Pena</p>
                        </div>
                    </div>

                    {/* Instance for Dr. LoAnn T Trinh */}
                    <div className="col-md-4 instance-card mb-4">
                        <a href="trinh-medical.html" className="card mb-4">
                            <img src={loann} className="card-img-top" alt="Dr. LoAnn T Trinh" style={{ objectFit: 'cover' }} />
                        </a>
                        <div className="card-body">
                            <h5 className="card-title">Dr. LoAnn T Trinh</h5>
                            <p className="card-text"><strong>Location:</strong> 8557 Research Boulevard, Town North Medical and Health Suite 128, Austin, TX 78758</p>
                            <p className="card-text"><strong>Phone:</strong> 512-836-7399</p>
                            <p className="card-text"><strong>Additional Languages Spoken:</strong> Spanish, Vietnamese</p>
                            <p className="card-text"><strong>Gender:</strong> Female</p>
                            <p className="card-text"><strong>Name:</strong> Dr. LoAnn T Trinh</p>
                        </div>
                    </div>

                    {/* Add more healthcare instances here using the same pattern */}
                </div>

                {/* Footer with Total Instances and Pages */}
                <footer className="text-center mt-5">
                    <p id="footer-info" className="footer-info">Total Instances: {totalInstances} | Total Pages: {totalPages}</p>
                </footer>
            </div>
        </div>
    );
};

export default HealthcarePrograms;
