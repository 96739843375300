// src/components/LegalServiceDetail.tsx

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../styles.css';
import LegalImage from '../images/legal.jpg'

interface LegalService {
    name: string;
    addrln1: string;
    addrln2?: string;
    city: string;
    state: string;
    zip: string;
    phones: string;
    hours: string;
    description: string;
    url: string;
    link: string;
}

interface RelatedItem {
    id?: number;
    facility_name?: string; // For healthcare items
    name?: string; // For educational items
    city: string;
    state: string;
    description?: string;
}

const LegalServiceDetail: React.FC = () => {
    const { Name } = useParams<{ Name?: string }>();
    const [legalServiceData, setLegalServiceData] = useState<LegalService | null>(null);
    const [relatedEducation, setRelatedEducation] = useState<RelatedItem[]>([]);
    const [relatedHealthcare, setRelatedHealthcare] = useState<RelatedItem[]>([]);
    const [image, setImage] = useState<string>(LegalImage); // Default fallback image
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!Name) {
            setError('Name is missing.');
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);

        // Fetch the main legal service data
        fetch(`https://immigraid-backend.immigraid.me/api/legal-services-data?name=${encodeURIComponent(Name)}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setLegalServiceData(data);
                fetchImage(Name); // Fetch dynamic image
                fetchRelatedEducation(); // Fetch related educational programs
                fetchRelatedHealthcare(); // Fetch related healthcare services
            })
            .catch((error) => {
                console.error("Error fetching legal service data:", error);
                setError('Error fetching legal service data');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [Name]);

    const fetchImage = async (facilityName: string) => {
        try {
            const response = await fetch(
                `https://api.unsplash.com/search/photos?query=${encodeURIComponent(
                    facilityName
                )}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                const imageUrl = data.results[0].urls.regular; // Get the first image result
                setImage(imageUrl);
            } else {
                console.warn(`No image found for ${facilityName}. Using default.`);
            }
        } catch (err) {
            console.error(`Error fetching image for ${facilityName}:`, err);
        }
    };


    const fetchRelatedEducation = () => {
        axios.get('https://immigraid-backend.immigraid.me/api/related-education')
            .then(response => {
                setRelatedEducation(response.data.slice(0, 3)); // Limit to 3 related items
            })
            .catch(error => {
                console.error('Error fetching related educational programs:', error);
            });
    };

    const fetchRelatedHealthcare = () => {
        axios.get('https://immigraid-backend.immigraid.me/api/related-healthcare')
            .then(response => {
                setRelatedHealthcare(response.data.slice(0, 3)); // Limit to 3 related items
            })
            .catch(error => {
                console.error('Error fetching related healthcare services:', error);
            });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!legalServiceData) {
        return <div>No legal service found.</div>;
    }

    const { name, addrln1, addrln2, city, state, zip, phones, hours, description, url, link } = legalServiceData;
    // Construct the map URL
    const address = `${addrln1 || ''}, ${addrln2 || ''}, ${city || ''}, ${state || ''}, ${zip || ''}`;
    const mapUrl = `https://maps.google.com/maps?q=${encodeURIComponent(address)}&t=&z=13&ie=UTF8&iwloc=&output=embed`;

    return (
        <div className="container mt-5">
            <h1>{Name || 'No Facility Name'}</h1>
            <p><strong>Location:</strong> {addrln1 || 'Not Provided'}, {addrln2 || ''} {city || ''}, {state || ''} {zip || ''}</p>
            <p><strong>Description:</strong> {description || 'Not Provided'}</p>
            {phones && <p><strong>Phones:</strong> {phones}</p>}
            {hours && <p><strong>Hours:</strong> {hours}</p>}
            {link && <p><strong>Link:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></p>}

            {/* Image Section */}
            <section className="mb-4">
                <h2>Image</h2>
                <div style={{ width: '100%', maxHeight: '400px', overflow: 'hidden' }}>
                    <img
                        src={image}
                        alt={`Image for ${name || 'Legal Service'}`}
                        style={{ width: '100%', objectFit: 'cover' }}
                    />
                </div>
            </section>


            {/* Map Section */}
            <section className="mb-4">
                <h2>Map</h2>
                <div style={{ width: '100%', height: '500px' }}>
                    <iframe
                        src={mapUrl}
                        style={{ border: 0, width: '100%', height: '100%' }}
                        allowFullScreen
                        loading="lazy"
                        title="Legal Service Location">
                    </iframe>
                </div>
            </section>

            {/* Related Educational Programs */}
            <section className="mb-4">
                <h2>Related Educational Programs</h2>
                <div className="d-flex flex-row justify-content-start">
                    {relatedEducation.map((item, index) => (
                        <div key={index} className="card" style={{ width: '18rem', margin: '10px' }}>
                            <div className="card-body">
                                <h5 className="card-title">{item.name}</h5>
                                <p className="card-text">{item.city}, {item.state}</p>
                                <Link to={`/programs/${item.id}`}>View Details</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Related Healthcare Services */}
            <section className="mb-4">
                <h2>Related Healthcare Services</h2>
                <div className="d-flex flex-row justify-content-start">
                    {relatedHealthcare.map((item, index) => (
                        <div key={index} className="card" style={{ width: '18rem', margin: '10px' }}>
                            <div className="card-body">
                                <h5 className="card-title">{item.facility_name}</h5>
                                <p className="card-text">{item.city}, {item.state}</p>
                                <Link to={`/healthcare/${encodeURIComponent(item.facility_name || '')}`}>View Details</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}

export default LegalServiceDetail;
