import React, { useEffect } from 'react';
import { Navbar, Nav, Carousel, Container, Row, Col, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles.css'; // Link to the external CSS file
import splash1 from '../images/splash1.webp';
import splash2 from '../images/splash2.jpg';
import splash3 from '../images/splash3.jpg';

const Home: React.FC = () => {
    useEffect(() =>{
        document.title = "ImmigrAid"; 
    }, []);
    return (
        <div>
            <Container>
                <h1 className="mt-5">Welcome to our Website!</h1>
                <p>
                    This website is dedicated to helping immigrants access essential resources in <span className="highlight">education</span>, <span className="highlight">healthcare</span>, and <span className="highlight">legal support</span>. We strive to connect immigrants with programs, services, and information to help them thrive in their new community.
                </p>

                <h1 className="mt-5">¡Bienvenido a nuestro sitio web!</h1>
                <p>
                    Este sitio web está dedicado a ayudar a los inmigrantes a acceder a recursos esenciales en <span className="highlight">educación</span>, <span className="highlight">atención médica</span> y <span className="highlight">apoyo legal</span>. Nos esforzamos por conectar a los inmigrantes con programas, servicios e información para ayudarlos a prosperar en su nueva comunidad.
                </p>

                {/* Carousel */}
                <Carousel className="mt-5">
                    <Carousel.Item>
                        <img src={splash1} className="d-block w-75 mx-auto" alt="Slideshow Image 1" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={splash2} className="d-block w-75 mx-auto" alt="Slideshow Image 2" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={splash3} className="d-block w-75 mx-auto" alt="Slideshow Image 3" />
                    </Carousel.Item>
                </Carousel>

                <section className="mt-5">
                    <h2>About Our Mission</h2>
                    <p>
                        Immigrants often face challenges when it comes to finding reliable information and support for education, healthcare, and legal services. Our site aims to bridge that gap by providing resources, guidance, and connections to institutions and programs specifically tailored to help immigrants overcome language and cultural barriers.
                    </p>
                    <p>
                        Explore our educational programs to find English language courses, academic programs, and other learning opportunities. Check out our healthcare resources to locate clinics and services that offer language support. Lastly, visit our legal section to find organizations that assist with immigration and legal advice, ensuring immigrants can navigate their new environment confidently and safely.
                    </p>

                    <h2>Acerca de Nuestra Misión</h2>
                    <p>
                        Los inmigrantes a menudo enfrentan desafíos cuando se trata de encontrar información confiable y apoyo para educación, atención médica y servicios legales. Nuestro sitio tiene como objetivo cerrar esa brecha proporcionando recursos, orientación y conexiones con instituciones y programas diseñados específicamente para ayudar a los inmigrantes a superar las barreras lingüísticas y culturales.
                    </p>
                    <p>
                        Explore nuestros programas educativos para encontrar cursos de inglés, programas académicos y otras oportunidades de aprendizaje. Consulte nuestros recursos de atención médica para ubicar clínicas y servicios que ofrecen soporte lingüístico. Por último, visite nuestra sección legal para encontrar organizaciones que ayuden con asesoramiento legal y de inmigración, garantizando que los inmigrantes puedan navegar en su nuevo entorno con confianza y seguridad.
                    </p>

                    <h2>关于我们的使命</h2>
                    <p>
                        移民在寻找教育、医疗保健和法律服务的可靠信息和支持时常常面临挑战。我们的网站旨在通过提供资源、指导以及与专门帮助移民克服语言和文化障碍的机构和项目的联系来弥合这一差距。
                    </p>
                    <p>
                        探索我们的教育计划，寻找英语课程、学术计划和其他学习机会。查看我们的医疗保健资源，找到提供语言支持的诊所和服务。最后，请访问我们的法律部分，查找协助移民和提供法律建议的组织，确保移民能够自信、安全地适应新环境。
                    </p>
                </section>

                {/* API Documentation Section */}
                <section className="mt-5 text-center">
                    <h2>Our API Documentation</h2>
                    <p>Explore our API documentation to integrate ImmigriAid data into your applications. Click the button below to view more details.</p>
                    <a href="https://documenter.getpostman.com/view/38677006/2sAXxLBDsM" className="btn btn-primary" target="_blank" rel="noopener noreferrer">View API Documentation</a>
                </section>

                {/* Video */}
                <div className="video-container mt-5">
                    <iframe 
                        src="https://www.youtube.com/embed/6QPAQ1BWnOo" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen 
                        title="Introduction Video"
                    />
                </div>
            </Container>

            {/* Footer */}
            <footer className="text-center mt-5">
                <p>&copy; 2024 Our Site</p>
            </footer>
        </div>
    );
};

export default Home;
