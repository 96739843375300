import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LegalImage from '../images/legalCard.jpeg';

interface LegalService {
  Name: string;
  addrln1: string;
  addrln2?: string;
  city: string;
  state: string;
  zip: string;
  phones: string;
  hours: string;
  description: string;
  url: string;
  link: string;
}

const LegalServicesPage: React.FC = () => {
  const [legalData, setLegalData] = useState<LegalService[]>([]);
  const [filteredLegalData, setFilteredLegalData] = useState<LegalService[]>([]);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [pageInput, setPageInput] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>(''); // Search input
  const [filterState, setFilterState] = useState<string>(''); // State filter
  const [filterCity, setFilterCity] = useState<string>(''); // City filter
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false); // Tracks if search is active

  const defaultImageUrl = LegalImage;

  useEffect(() => {
    document.title = "Legal Assistance";

    fetch('https://immigraid-backend.immigraid.me/api/legal-services-data')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setLegalData(data);
          setFilteredLegalData(data); // Set initial filtered data
          setLoading(false);
        } else {
          console.error('Unexpected data format:', data);
          setError('Unexpected data format received.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching legal services data:", error);
        setError('Error fetching legal services data');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchImageForService = async (service: LegalService) => {
        try {
          const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${service.Name}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          );
          const data = await response.json();
          const imageUrl = data.results[0]?.urls?.regular || defaultImageUrl;
          setImages((prev) => ({ ...prev, [service.Name]: imageUrl }));
        } catch (error) {
          console.error(`Error fetching image for ${service.Name}:`, error);
          setImages((prev) => ({ ...prev, [service.Name]: defaultImageUrl }));
        }
      };

      legalData.forEach(fetchImageForService);
    };

    if (legalData.length > 0) {
      fetchImages();
    }
  }, [legalData]);

  const handleSearch = () => {
    let updatedLegalData = legalData;

    // Apply search filter
    if (searchInput) {
      updatedLegalData = updatedLegalData.filter(
        (service) =>
          service.Name.toLowerCase().includes(searchInput.toLowerCase()) ||
          service.description.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Apply state filter
    if (filterState) {
      updatedLegalData = updatedLegalData.filter(
        (service) => service.state.toLowerCase().includes(filterState.toLowerCase())
      );
    }

    // Apply city filter
    if (filterCity) {
      updatedLegalData = updatedLegalData.filter(
        (service) => service.city.toLowerCase().includes(filterCity.toLowerCase())
      );
    }

    setFilteredLegalData(updatedLegalData);
    setIsSearchActive(true);

    // If no results match the filters, set an error message
    if (updatedLegalData.length === 0) {
      setError('No services match your search criteria.');
    } else {
      setError(null); // Clear error if results are found
    }

    setCurrentPage(1); // Reset to the first page
  };

  const clearFilters = () => {
    setSearchInput('');
    setFilterState('');
    setFilterCity('');
    setFilteredLegalData(legalData); // Reset to all services
    setError(null); // Clear error message
    setIsSearchActive(false);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredLegalData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLegalData.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const goToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageInput('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  if (loading) {
    return <p>Loading legal services data...</p>;
  }

  if (error && isSearchActive) {
    return <p>{error}</p>;
  }

  return (
    <div className="container">
      <h1 className="mt-5">Legal Services Resources</h1>

      {/* Search and Filter Section */}
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Search services..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{ padding: '8px', flex: 1 }}
        />
        <input
          type="text"
          placeholder="Filter by state..."
          value={filterState}
          onChange={(e) => setFilterState(e.target.value)}
          style={{ padding: '8px', width: '150px' }}
        />
        <input
          type="text"
          placeholder="Filter by city..."
          value={filterCity}
          onChange={(e) => setFilterCity(e.target.value)}
          style={{ padding: '8px', width: '150px' }}
        />
        <button onClick={handleSearch} style={{ padding: '8px 16px' }}>
          Search
        </button>
        <button onClick={clearFilters} style={{ padding: '8px 16px' }}>
          Clear Filters
        </button>
      </div>

      <div style={dropdownStyle}>
        <label htmlFor="itemsPerPage">Items per page: </label>
        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>
      <div style={cardContainerStyle}>
        {currentItems.map((service, index) => (
          <div key={index} className="service-card" style={serviceCardStyle}>
            <div style={{ width: '100%', height: '200px', overflow: 'hidden', marginBottom: '10px' }}>
              <img
                src={images[service.Name] || defaultImageUrl}
                alt={`Image of ${service.Name || 'Legal Service'}`}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
            </div>
            <h2>
              <Link to={`/legalservices/${encodeURIComponent(service.Name)}`}>
                {service.Name || "Unknown Facility"}
              </Link>
            </h2>
            <p>
              {service.addrln1 || "Unknown Address"}, {service.addrln2 ? `${service.addrln2}, ` : ''}{service.city || "Unknown City"}, {service.state || "Unknown State"} {service.zip || "Unknown ZIP"}
            </p>
            <p>Phone: {service.phones || "N/A"}</p>
            <p>Hours: {service.hours || "N/A"}</p>
            <a href={service.link || "#"} target="_blank" rel="noopener noreferrer">
              {service.url ? "Visit Website" : "No Website"}
            </a>
          </div>
        ))}
      </div>

      {/* Pagination controls */}
      <div style={paginationStyle}>
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      {/* Jump to specific page */}
      <div style={jumpToPageStyle}>
        <input
          type="number"
          value={pageInput}
          onChange={handlePageInputChange}
          placeholder={String(currentPage)}
          min="1"
          max={totalPages}
          style={pageInputStyle}
        />
        <button onClick={goToPage} style={goButtonStyle}>
          Go
        </button>
      </div>
    </div>
  );
};

// Styles
const serviceCardStyle: React.CSSProperties = {
  border: '1px solid #ddd',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  flex: '0 1 30%',
  margin: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '250px',
  height: '650px',
  overflow: 'hidden',
};

const cardContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '8px',
  maxWidth: '100%',
  padding: '16px',
  boxSizing: 'border-box',
  margin: '0 auto',
};

const dropdownStyle: React.CSSProperties = {
  marginBottom: '20px',
};

const paginationStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
};

const jumpToPageStyle: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const pageInputStyle: React.CSSProperties = {
  marginRight: '10px',
  padding: '5px',
  width: '60px',
};

const goButtonStyle: React.CSSProperties = {
  padding: '5px 10px',
};

export default LegalServicesPage;
